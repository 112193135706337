import { DefaultFieldType } from '../components/AdvancedFiltersValueField';
import { Condition } from '../../../../../../../services/queryService';

// ----------------

export function getDefaultValue(
  condition: Condition,
  defaultFieldType: DefaultFieldType | null
): { defaultValue: any; fieldType: DefaultFieldType | null } {
  switch (condition) {
    case 'equal':
    case 'not-equal': {
      switch (defaultFieldType) {
        case 'text': {
          return { defaultValue: '', fieldType: 'text' };
        }
        case 'select': {
          return { defaultValue: null, fieldType: 'select' };
        }
        case 'multi-select': {
          return { defaultValue: null, fieldType: 'multi-select' };
        }
        case 'date-picker': {
          return { defaultValue: null, fieldType: 'date-picker' };
        }
        case 'switch': {
          return { defaultValue: false, fieldType: 'switch' };
        }
        case 'existence': {
          return { defaultValue: null, fieldType: 'select' };
        }
        case null: {
          return { defaultValue: null, fieldType: null };
        }
      }
      break;
    }

    case 'contain':
    case 'end-with':
    case 'begin-with':
    case 'not-contain':
    case 'not-end-with':
    case 'not-begin-with': {
      return { defaultValue: '', fieldType: 'text' };
    }

    case 'less-than':
    case 'greater-than':
    case 'less-than-or-equal':
    case 'greater-than-or-equal': {
      return defaultFieldType === 'date-picker' ? { defaultValue: null, fieldType: 'date-picker' } : { defaultValue: '', fieldType: 'text' };
    }

    case 'empty':
    case 'not-empty': {
      return { defaultValue: '_', fieldType: null };
    }
  }
}
