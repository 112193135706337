import React from 'react';

// Components

import MultiSelect, { MultiSelectProps } from '../../../../../../common/MultiSelect';
import DatePicker, { DatePickerProps } from '../../../../../../common/DatePicker';
import TextField, { TextFieldProps } from '../../../../../../common/TextField';
import Select, { SelectProps } from '../../../../../../common/Select';
import Switch, { SwitchProps } from '@mui/material/Switch';

// Types

import { Condition } from '../../../../../../../../services/queryService';

// -------- Types --------

type Props = {
  defaultFieldType: DefaultFieldType;
  multiSelectProps: MultiSelectProps;
  datePickerProps: DatePickerProps;
  textFieldProps: TextFieldProps;
  switchProps: SwitchProps;
  selectProps: SelectProps;
  condition: Condition;
};

export type DefaultFieldType = 'text' | 'select' | 'multi-select' | 'date-picker' | 'switch' | 'existence';

// ----------------

const AdvancedFiltersValueField: React.FC<Props> = (props) => {
  if (props.defaultFieldType === 'existence') {
    return null;
  }

  switch (props.condition) {
    case 'equal':
    case 'not-equal': {
      return (
        <DefaultField
          multiSelectProps={props.multiSelectProps}
          defaultFieldType={props.defaultFieldType}
          datePickerProps={props.datePickerProps}
          textFieldProps={props.textFieldProps}
          switchProps={props.switchProps}
          selectProps={props.selectProps}
        />
      );
    }

    case 'contain':
    case 'end-with':
    case 'begin-with':
    case 'not-contain':
    case 'not-end-with':
    case 'not-begin-with': {
      return <TextField {...props.textFieldProps} />;
    }

    case 'less-than':
    case 'greater-than':
    case 'less-than-or-equal':
    case 'greater-than-or-equal': {
      return props.defaultFieldType === 'date-picker' ? <DatePicker {...props.datePickerProps} /> : <TextField {...props.textFieldProps} />;
    }

    default: {
      return null;
    }
  }
};

export default AdvancedFiltersValueField;
export { Props as AdvancedFiltersValueFieldProps };

// ----------------

type DefaultFieldProps = Omit<Props, 'condition'>;

const DefaultField: React.FC<DefaultFieldProps> = (props) => {
  switch (props.defaultFieldType) {
    case 'text': {
      return <TextField {...props.textFieldProps} />;
    }

    case 'select': {
      return <Select {...props.selectProps} />;
    }

    case 'multi-select': {
      return <MultiSelect {...props.multiSelectProps} />;
    }

    case 'date-picker': {
      return <DatePicker {...props.datePickerProps} />;
    }

    case 'switch': {
      return <Switch {...props.switchProps} />;
    }

    case 'existence': {
      return <Select {...props.selectProps} />;
    }
  }
};
